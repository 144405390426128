import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  productSummaryInformation:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",

  },
  
  productImage: {
    width: "150px",
    height: "150px",
    objectFit: "contain", //
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column  ",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  productName: {
    textTransform: "capitalize",
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  productSize: {
    textTransform: "capitalize",
    fontFamily: "Roboto",
  },
  btnSelected: {
    textTransform: "none",
    marginLeft: theme.spacing(1),
  },
  menuButtonGroup:{
    float: "right",
	  display: "block",
    marginTop:"25px"
  }
}));

const Toolbar = (props) => {
  const classes = useStyles();
  const {device} = props;
  const deviceName = device?`${device.brand} ${device.model} ${device.size}`:''
  return (
    <Grid container spacing={4}>
      <Grid item lg={12} sm={12} xl={12} xs={12} m={12}>
        <div className={classes.productSelectedButton}>
          {device&&(
          <div className={classes.container}>
            <img
              className={classes.productImage}
              alt={deviceName }
              src={
                device.image_url
                  ? device.image_url
                  : `https://bettercart.ca/resources/images/no-picture-available.png`
              }
            />
            <div className={classes.titleContainer}>
              <div className={classes.productName}>
                {/* #{productSelected.upc} - {productSelected.brandName&&`[${productSelected.brandName}]`} {productSelected.name} */}
                {deviceName}
              </div>
              <div className={classes.productSize}>{device.size}</div>
              <div className={classes.productSummaryInformation}>
                
              </div>
            </div>
          </div>
          )}
        </div>
      
       
      </Grid>
    </Grid>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
