import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import {
  selectWeek
} from "../../store/actions/weekAction";
const useStyles = makeStyles((theme) => ({
  button: {
    height: '40px',      
    '& .MuiButton-endIcon': {
      marginLeft: 8
    },
    '& .MuiButton-label': { 
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    '&:hover': {
      backgroundColor: 'rgba(32, 79, 108, 0.04)',
      borderColor: '#204F6C',
      borderWidth: '2px',
      cursor: 'pointer'
    }
  },  
  }));
// import StoreIcon from "@material-ui/icons/Store";

export function SelectWeek({className}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const weeks = useSelector(state => state.week.weeks);
  const weekSelected = useSelector(state => state.week.weekSelected);
  // console.log(weeks)
  const dispatch = useDispatch()
  const handleChange = (week) => {    
    dispatch(selectWeek(week))
    setAnchorEl(null);
  };    

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick  = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (  
    <>
      <Button
        variant="outlined"
        className={clsx(className, classes.button)}
        color="primary"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon/>}
      >
       {weekSelected.firstday}
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {weeks.map((week) => (
          <MenuItem
            key={week.first}           
            selected={week.first === weekSelected.firstday}
            onClick={() => handleChange(week)}
          >
            {week.firstday}
          </MenuItem>
        ))}
      </Menu>      
    </>
   
  );
}
export const MemoizedSelectWeek = React.memo(SelectWeek);
