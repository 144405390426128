import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import {  
  TableSortLabel
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  sortLabel:{
    fontSize: "1rem",    
  },
}));
export default function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.nowrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}
