import React, { useRef }  from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProductList
} from "../../store/actions/reportAction";
import ConfigDialog from "./Config/ConfigDialog";

const useStyles = makeStyles((theme) => ({
    button: {
      height: '40px',      
      '& .MuiButton-endIcon': {
        marginLeft: 8
      },
      '& .MuiButton-label': { 
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1.25rem',
        fontWeight: 500,
      },
      '&:hover': {
        backgroundColor: 'rgba(32, 79, 108, 0.04)',
        borderColor: '#204F6C',
        borderWidth: '2px',
        cursor: 'pointer'
      }
    },  
  }));
// import StoreIcon from "@material-ui/icons/Store";

export default function SelectProductList({className}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const productLists = useSelector(state => state.report.productLists);
  const productListSelected = useSelector(state => state.report.productListSelected);
  const dialogConfig = useRef(null);
  const handleOpenConfig = () => {
    dialogConfig.current.doOpen();
  };
  // console.log(productListSelected)
  const dispatch = useDispatch()
  const handleChange = (productList) => {    
    dispatch(selectProductList(productList))
    setAnchorEl(null);
  };    

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick  = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (  
    <>
      {productListSelected&&
        <Button
          variant="outlined"
          className={clsx(className, classes.button)}
          color="primary"
          onClick={handleClick}
          endIcon={<ExpandMoreIcon/>}
        >
        {productListSelected.name}
        </Button>
      }
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {productLists&&productListSelected&&productLists.map((item,index) => (
          <MenuItem
            key={index}           
            selected={item.id ===  productListSelected.id}
            onClick={() => handleChange(item)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>   
      <IconButton color="primary" aria-label="Customize Columns" component="span" onClick={handleOpenConfig}>
          <i class="material-icons">settings</i> 
      </IconButton>
      <ConfigDialog
          ref={dialogConfig}          
        />   
    </>
   
  );
}

