import React  from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectOneCustomerProductDialog from "./SelectOneCustomerProductDialog";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerProducts,
  selectCustomerProduct
  } from "../../store/actions/customerProductAction";
const useStyles = makeStyles((theme) => ({
  button: {
    height: '40px',      
    '& .MuiButton-endIcon': {
      marginLeft: 8
    },
    '& .MuiButton-label': { 
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    '&:hover': {
      backgroundColor: 'rgba(32, 79, 108, 0.04)',
      borderColor: '#204F6C',
      borderWidth: '2px',
      cursor: 'pointer'
    }
  },  
}));
// import StoreIcon from "@material-ui/icons/Store";

export function SelectOneCustomerProductDropDowButton({className}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch()
  const state = useSelector(state => state);
  
  // const [products, setProducts] = React.useState([]);
  const storesSelected = state.store.storesSelected;
  const products = state.customerProduct.products;

  const handleClickOpen = () => {
    // alert('test');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const setCustomerProductSelected = (product)=>{
    dispatch(selectCustomerProduct(product));
  }
  // useEffect(() => {
  //   dispatch(fetchCustomerProducts(state.session.user.default_customer_product_id)).then((customerProductRet)=>{
  //     // console.log(productSelected.id||customerProductRet.defaultSelectProduct)     
  //     setProducts(customerProductRet.products)
  //     dispatch(selectCustomerProduct(customerProductRet.defaultSelectProduct));
  //     setLoading(false)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }, []);
  
  return (
    <>
    {!state.customerProduct.loading&&
      <>
        <Button
          variant="outlined"
          className={clsx(className, classes.button)}
          color="primary"
          onClick={handleClickOpen}
        endIcon={<ExpandMoreIcon/>}

        >Select a product</Button>
        <SelectOneCustomerProductDialog
          items={products}
          setItemSelected={setCustomerProductSelected}      
          open={open}
          handleClose={handleClose}
          storesSelected={storesSelected}       
        />
      </>
    }
    </>
  );
}
export const MemoizedSelectCustomerProduct = React.memo(SelectOneCustomerProductDropDowButton);
