import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectOneProductDialog from "./SelectOneDialog";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import {
  selectProduct
  } from "../../store/actions/productAction";
const useStyles = makeStyles((theme) => ({
  button: {
    height: '40px',    
    '& .MuiButton-endIcon': {
      marginLeft: 8
    },
    '& .MuiButton-label': { 
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    '&:hover': {
      backgroundColor: 'rgba(32, 79, 108, 0.04)',
      borderColor: '#204F6C',
      borderWidth: '2px',
      cursor: 'pointer'
    }
  },  
}));
// import StoreIcon from "@material-ui/icons/Store";

export function SelectOneProductDropDowButton({className}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch()
  const state = useSelector(state => state);
  const products = state.product.products
  const storesSelected = state.store.storesSelected;

  const handleClickOpen = () => {
    // alert('test');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const setProductSelected = (product)=>{
    dispatch(selectProduct(product));
  }
  return (
    <>
    {!state.product.loading&&
      <>
        <Button
          variant="outlined"
          className={clsx(className, classes.button)}
          color="primary"
          onClick={handleClickOpen}
        endIcon={<ExpandMoreIcon/>}

        >Select a product</Button>
        <SelectOneProductDialog
          items={products}
          setItemSelected={setProductSelected}      
          open={open}
          handleClose={handleClose}
          storesSelected={storesSelected}       
        />
      </>
    }
    </>
  );
}
export const MemoizedSelectProduct = React.memo(SelectOneProductDropDowButton);
